<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-end>
      <v-flex xs12>
        <!-- <span><b>Organisation Assigned Amount :</b></span> &nbsp;<span>{{orgAmout.orgAssignedAmount}}</span> -->
      </v-flex>
      <v-flex xs12>
        <v-card elevation="3" style="border-radius: 20px">
          <v-flex
            xs12
            align-self-center
            style="background-color: rgb(230, 230, 230)"
          >
            <v-layout
              wrap
              pr-lg-3
              pa-2
              style="font-family: poppinsmedium; font-size: 14px"
            >
              <v-flex
                xs12
                text-left
                class="xlarge"
                style="font-family: poppinsbold"
              >
                {{ project }}
              </v-flex>

              <v-flex xs6 text-left> Employee  : {{ employee }} </v-flex>
            </v-layout>
          </v-flex>

          <v-layout wrap pa-8>
            <v-flex xs3>
              <span class="RR"> Organisation </span>
              <br />
              <span class="RB">{{ $route.query.name }}</span>
            </v-flex>
            <v-flex xs3>
              <span class="RR">Financial Year</span>
              <br />
              <span class="RB">{{ myYear }}</span>
            </v-flex>
            <v-flex xs3>
              <span class="RR">Project Target (INR)</span>
              <br />
              <span class="RB">{{ myTarget }}</span>
            </v-flex>
            <v-flex xs3>
              <span class="RR"> Split Amount (INR) </span>
              <br />
              <span class="RB">{{ splitAmount }}</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 md3 text-right pt-4>
        <v-select
          shaped
          outlined
          dense
          label="Status"
          :items="filters"
          v-model="filter"
        >
        </v-select>
      </v-flex>

      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr style="font-family: poppinsmedium">
                <th class="text-left">Date</th>
                <th class="text-left">Created Date</th>
                <!-- <th class="text-left">Committed Date</th> -->
                <th class="text-left"><span style="color: green">Already received  (INR)</span></th>
                <th class="text-left"><span style="color: #FEE60D">Committed and confirmed (INR)</span></th>
                <th class="text-left"><span style="color: blue">Committed but not confirmed (INR)</span></th>
                <th class="text-left"><span style="color: red">To be raised (INR)</span></th>

                <th class="text-left" v-if="filter == 'Approved'">Edited</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="font-family: poppinsmedium"
                class="text-left"
                v-for="(item, i) in tableInformation"
                :key="i"
              >
                <td>{{ formatDate(item.date) }}</td>
                <td>{{ formatDate(item.create_date) }}</td>


                <!-- <td>
                  <span v-if="item.date">
                    {{ formatDate(item.date) }}
                  </span>
                  <span v-else> - </span>
                </td> -->

                <!-- <td>
                  <span v-if="item.committedDate">
                    {{ formatDate(item.committedDate) }}
                  </span>
                  <span v-else> - </span>
                </td> -->

                <td>{{ item.amountAchieved }}</td>
                <td>{{ item.committed }}</td>
                <td>{{ item.notconfirmed }}</td>

                  <td>{{ item.toberaised }}</td>


                <template v-if="filter == 'Approved'">
                  <td v-if="item.isAmountChanged == true">
                    {{ item.previousAmount }}
                  </td>
                  <td v-else>-</td>
                </template>
                <td>{{ item.projectStatus }}</td>
              </tr>
              <tr style="font-family: poppinsmedium" class="text-left">
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>Total</td>
                <td>Total</td>
                <td></td>
              </tr>
              <tr style="font-family: poppinsmedium" class="text-left">
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <b> {{ amountReceived }} (INR) </b>
                </td>
                <td>
                  <b>{{ committed }} (INR) </b>
                </td>
                <td>
                  <b>{{ notConfirmed }} (INR) </b>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      project: null,
      employee: null,
      myYear: null,
      myTarget: null,
      filter: "Pending",
      filters: ["Pending", "Approved", "Rejected"],
      tableInformation: [],
      orgAmout: [],
      Fyear: null,
      FyearTarget: 0,
      amountReceived: 0,
      committed: 0,
      notConfirmed: 0,
      appLoading: false,
      ServerError: false,

      year: null,
      totalTarget: null,
      splitAmount: null,

      mainData: null,
    };
  },
  // mounted() {
  //   this.getData();
  // },

  beforeMount() {
    this.getData();
  },

  watch: {
    filter() {
      this.getData();
    },
  },

  methods: {
    formatDate(item) {
      return item.substring(0, 10);
    },

    getData() {
      this.amountReceived = 0;
      this.committed = 0;
      this.notConfirmed = 0;
      this.appLoading = true;
      axios({
        url: "/split/get/details",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          status: this.filter,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.project =
            response.data.projectsplit.targetid.projectid.projectName;
            this.employee = response.data.projectsplit.empId.name;
            this.mainData = response.data.projectsplit;
            this.myYear = this.mainData.targetid.fyear.fyear;
            this.myTarget = this.mainData.targetid.totalTarget;
            //     if (this.mainData.targetid != null) {
            //   this.year = this.mainData.targetid.fYear.fYear;
            // }
            this.tableInformation = response.data.data;
            // let c= response.data.projectsplit
            // this.orgAmout = response.data.split;
            // this.Fyear = response.data.split.targetid.Fyear;
            // console.log(" this.mainData", this.mainData);
            this.totalTarget = response.data.totalTarget;
            this.splitAmount = response.data.split;
            for (let i = 0; i < this.tableInformation.length; i++) {
              this.amountReceived =
                this.amountReceived + this.tableInformation[i].amountAchieved;
              this.committed =
                this.committed + this.tableInformation[i].committed;
              this.notConfirmed =
                this.notConfirmed + this.tableInformation[i].notconfirmed;
            }
            this.appLoading = false;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.v-simple-table {
  font-family: poppinsmedium !important ;
}
</style>